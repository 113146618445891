import PropTypes from "prop-types";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { categoriesContent } from "./content";

const DoneCategory = ({ category, correct, handleSumit }) => {
  const { currentPoints } = useContext(AppContext);

  return (
    <div className="done-category">
      <div className="done-category__results">
        <h1>{categoriesContent.done.results}</h1>
        {categoriesContent.done.correct(correct, category.questions.length)}
      </div>
      <div className="done-category__overall">
        <h1>{categoriesContent.done.overall}</h1>
        <span>{categoriesContent.done.points(currentPoints)}</span>
      </div>
      {category.content && <span className="text-center" dangerouslySetInnerHTML={{ __html: category.content }}></span>}
      {category.description && <span className="done-category__desc">{category.description}</span>}
      <h4>{category.source && <span dangerouslySetInnerHTML={{ __html: category.source }}></span>}</h4>
      <button className="done-category__submit" onClick={handleSumit}>
        {categoriesContent.done.continue}
      </button>
    </div>
  );
};

DoneCategory.propTypes = {
  category: PropTypes.shape({
    content: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string,
    order: PropTypes.number,
    questions: PropTypes.array,
    source: PropTypes.string,
    title: PropTypes.string,
  }),
  correct: PropTypes.number.isRequired,
  handleSumit: PropTypes.func.isRequired,
};
export default DoneCategory;
