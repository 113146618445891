import { useState } from 'react'

export default function useModal() {
  const [modal, setModal] = useState(false)
  const [modalContent, setModalContent] = useState('')

  let handleModalContent = (content = false) => {
    if (content) {
      setModalContent(content)
    }
  }

  let handleModal = (content = false) => {
    setModal(!modal)
    if (content) {
      setModalContent(content)
    }
  }

  return { modal, handleModal, modalContent, handleModalContent }
}
