import PropTypes from "prop-types";
import FirstPlaceIcon from "../../assets/icons/lb-1.png";
import SecondPlaceIcon from "../../assets/icons/lb-2.png";
import ThirdPlaceIcon from "../../assets/icons/lb-3.png";
import MyThrophy from "../../assets/ui/my-throphy.webp";
import ThropyIcon from "../../assets/ui/ThrophyIcon";

const UserRank = ({ rank, isActive, children }) => {
  switch (rank) {
    case 1:
      return (
        <div className="user-rank">
          <img src={FirstPlaceIcon} alt="first" className="mx-1" />
          {children}
          <ThropyIcon width="50" fill="#d4af37" />
        </div>
      );
    case 2:
      return (
        <div className="user-rank">
          <img src={SecondPlaceIcon} alt="second" className="mx-1" />
          {children}
          <ThropyIcon width="40" fill="#C0C0C0" />
        </div>
      );
    case 3:
      return (
        <div className="user-rank">
          <img src={ThirdPlaceIcon} alt="third" className="mx-1" />
          {children}
          <ThropyIcon width="30" fill="#CD7F32" />
        </div>
      );

    default:
      return (
        <div className="user-rank__throphy">
          <div className={`rank ${isActive && "active"}`}>{rank}</div>
          {children}
          <div className="user-rank__rank">{isActive && <img src={MyThrophy} alt="throphy"></img>}</div>
        </div>
      );
  }
};

UserRank.propTypes = {
  rank: PropTypes.number,
  isActive: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default UserRank;
