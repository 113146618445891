export const categoriesContent = {
  empty: "No active categories!",
  done: {
    results: "Results:",
    correct: (correct, total) => `You correctly answered ${correct} of ${total} questions.`,
    overall: "Overall score:",
    points: (total) => `${total} points.`,
    continue: "Continue",
  },
  header: (number) => `Section ${number}: `,
};
