import classNames from "clsx";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Question from "../Question/Question";
import { categoriesContent } from "./content";
import DoneCategory from "./DoneCategory";

const Category = ({ category, onChangeCategory = () => {}, activeCategory }) => {
  const [activeQuestion, setActiveQuestion] = useState({});
  const [activeIdnex, setActiveIndex] = useState(0);
  const [done, setDone] = useState(false);
  const [correct, setCorrect] = useState(0);

  useEffect(() => {
    if ("questions" in category && category.questions.length) {
      setActiveQuestion(category.questions[activeIdnex]);
    }
  });

  const handleSubmitClick = () => {
    const nextIndex = activeIdnex + 1;
    if (done) {
      setCorrect(0);
      onChangeCategory();
      setDone(false);
    } else if (nextIndex < category.questions.length) {
      setActiveQuestion(category.questions[nextIndex]);
      setActiveIndex(nextIndex);
    } else {
      setDone(true);
    }
  };

  return (
    <div
      className={classNames("wrapper-category", {
        hidden: category.id !== activeCategory.id,
      })}
    >
      <div className="wrapper-category__title">
        <h1>{categoriesContent.header(category.order)}</h1>
        <span>{category.title}</span>
      </div>
      <div className="category">
        <div className="category__content">
          {done ? (
            <DoneCategory category={category} correct={correct} handleSumit={handleSubmitClick} />
          ) : (
            category.questions.map((question) => (
              <Question
                key={question.id}
                question={question}
                activeQuestion={activeQuestion}
                onNextClick={handleSubmitClick}
                onAnswer={() => setCorrect(correct + 1)}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

Category.propTypes = {
  category: PropTypes.shape({
    content: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string,
    order: PropTypes.number,
    questions: PropTypes.array,
    source: PropTypes.string,
    title: PropTypes.string,
  }),
  onChangeCategory: PropTypes.func.isRequired,
  activeCategory: PropTypes.object,
};

export default Category;
