import { useContext } from "react";
import { createPortal } from "react-dom";
import { ModalContext } from "../../context/ModalContext";

const Modal = () => {
  let { modalContent, handleModal, modal } = useContext(ModalContext);

  const handleOutsideClick = (event) => {
    event.stopPropagation();
  };

  if (modal) {
    return createPortal(
      <div className="modal" onClick={() => handleModal()}>
        <div className="modal__content" onClick={handleOutsideClick} onTouchStart={handleOutsideClick}>
          <button className="modal__close" onClick={() => handleModal()}>
            &times;
          </button>
          {modalContent}
        </div>
      </div>,
      document.querySelector("#modal-root")
    );
  } else return null;
};

export default Modal;
