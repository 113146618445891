import PropTypes from "prop-types";
import { useContext, useEffect, useMemo } from "react";
import ThropyIcon from "../../assets/ui/ThrophyIcon";
import { SET_TOTAL_PROGRESS } from "../../context/actionTypes";
import { AppContext, AppDispatchContext } from "../../context/AppContext";
import { getNumberWithOrdinal } from "../../services/getOrdinal";

const InfoHeader = ({ categories, current }) => {
  const { userData, leaderboard, currentPoints, totalProgress, progress } = useContext(AppContext);
  const dispatch = useContext(AppDispatchContext);
  const progressValue = Math.trunc((progress / totalProgress) * 100);

  useEffect(() => {
    if (categories.length) {
      const totalQuestionsCount = categories.reduce((n, { questions }) => n + questions.length, 0);
      dispatch({ type: SET_TOTAL_PROGRESS, payload: totalQuestionsCount });
    }
  }, [categories]);

  const renderUserRank = useMemo(() => {
    if (leaderboard.length > 0) {
      const search = (user) => user.name === userData.name;
      const userIndex = leaderboard.findIndex(search);
      if (userIndex >= 0 && leaderboard[userIndex].rank) {
        return `${getNumberWithOrdinal(leaderboard[userIndex].rank)} place`;
      }
    }
    return "NA";
  }, [leaderboard]);

  return (
    <div className="info-header">
      <div className="info-header__progress">
        <div>{`Section ${current ? current.order : 0} of ${categories.length}`}</div>
        <div className="info-header__progress_bar">
          <div className="info-header__progress_pass" style={{ width: `${progressValue}%` }}></div>
        </div>
      </div>
      <div className="info-header__stats">
        <div className="info-header__points">
          <p>&#9733;</p>
          <span>{currentPoints}</span>
        </div>
        <div className="info-header__rank">
          <ThropyIcon width="24" fill="#d4af37" />
          {renderUserRank}
        </div>
      </div>
    </div>
  );
};

InfoHeader.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.string,
      order: PropTypes.number,
      questions: PropTypes.array,
      source: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  current: PropTypes.object,
};

export default InfoHeader;
