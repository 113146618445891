import { useContext, useMemo } from "react";
import QRCode from "react-qr-code";
import MyThrophy from "../../assets/ui/my-throphy.webp";
import ThropyIcon from "../../assets/ui/ThrophyIcon";
import { RAYALDEE_QR_URL } from "../../constants";
import { RESET_STATE } from "../../context/actionTypes";
import { AppContext, AppDispatchContext } from "../../context/AppContext";
import { ModalContext } from "../../context/ModalContext";
import Button from "../Button/Button";
import ContactUs from "../Modals/ContactUs";
import { endContent } from "./content";

const EndActivity = () => {
  const { userData, leaderboard, currentPoints } = useContext(AppContext);
  const dispatch = useContext(AppDispatchContext);
  let { handleModal } = useContext(ModalContext);

  const renderUserRank = useMemo(() => {
    if (leaderboard.length > 0) {
      const search = (user) => user.name === userData.name;
      const userIndex = leaderboard.findIndex(search);
      if (userIndex >= 0 && leaderboard[userIndex].rank) {
        return leaderboard[userIndex].rank;
      } else {
        return 0;
      }
    }
  }, [leaderboard, userData]);

  const handlePlayAgain = () => {
    dispatch({ type: RESET_STATE });
  };

  return (
    <div className="end-activity ">
      <div className="end-activity__content">
        <h1>{endContent.thanks}</h1>
        <img className="end-activity__throphy" src={MyThrophy} alt="throphy" />
        <div className="end-activity__scores">
          <div className="end-activity__points">
            <span>&#9733;</span>
            <div>{endContent.points}</div>
            <div className="end-activity__data">{currentPoints}</div>
          </div>
          <div className="end-activity__rank">
            <ThropyIcon width="48" fill="#d4af37" />
            <div>{endContent.rank}</div>
            <div className="end-activity__data">{`# ${renderUserRank ? renderUserRank : 0}`}</div>
          </div>
        </div>
        <p>{endContent.infoMsg}</p>
        <h2>{endContent.findMore}</h2>
        <div className="end-activity__qr">
          <div className="w-48">
            <QRCode
              size={256}
              value={RAYALDEE_QR_URL}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              viewBox={`0 0 256 256`}
              fgColor="#001f5b"
            ></QRCode>
          </div>
          <div className="scan-text">{endContent.scan}</div>
        </div>
        <h2 className="end-activity__signup">
          {endContent.signUp.start}
          <span className="end-activity__connected" onClick={() => handleModal(<ContactUs />)}>
            {endContent.signUp.link}
          </span>
          {endContent.signUp.end}
        </h2>
        <Button onClick={handlePlayAgain}>{endContent.exit}</Button>
      </div>
    </div>
  );
};

export default EndActivity;
