import { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import { headerContent } from "../Header/content";
import PrescribingModal from "../Modals/Prescribing";
import { sidebarContent } from "./content";

const Sidebar = () => {
  let { handleModal } = useContext(ModalContext);

  return (
    <div className="sidebar">
      <h1>{headerContent.indication.header}</h1>
      <span dangerouslySetInnerHTML={{ __html: headerContent.indication.content }}></span>
      <h1 className="mt-4">{headerContent.important.header}</h1>
      <ul className="sidebar__list" dangerouslySetInnerHTML={{ __html: headerContent.important.content }}></ul>
      <p>
        {sidebarContent.see}
        <span className="sidebar__link" onClick={() => handleModal(PrescribingModal)}>
          <b>{sidebarContent.link}</b>.
        </span>
      </p>
    </div>
  );
};

export default Sidebar;
