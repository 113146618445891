import classNames from "clsx";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useController } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Keyboard from "react-simple-keyboard";

const LAYOUTS = {
  SHIFT: "shift",
  DEFAULT: "default",
};

const BUTTONS = {
  ENTER: "{enter}",
  LOCK: "{lock}",
  SHIFT: "{shift}",
};

const INPUT = "INPUT";

const MainInput = ({
  name,
  control,
  maxLength = 150,
  required = false,
  errorMsg = "",
  list = "",
  position = "bottom",
  defaultValue = "",
  type = "text",
  label = "",
  isPhone = false,
}) => {
  const keyboard = useRef();
  const inputRef = useRef();
  const [layout, setLayout] = useState(LAYOUTS.DEFAULT);
  const [isKeybordVisible, setIsKeyboardVisible] = useState(false);
  const {
    field: { onChange, value },
    fieldState: { invalid },
  } = useController({
    name,
    control,
    ...(isPhone && { validate: (value) => value && value !== "+1" }),
    rules: { required },
    defaultValue,
  });

  useEffect(() => {
    if (!isKeybordVisible) {
      setLayout(LAYOUTS.DEFAULT);
    }
  }, [isKeybordVisible]);

  const handleFocus = () => {
    setIsKeyboardVisible(true);
    if (isPhone) {
      if (!value) {
        keyboard.current.setInput("+1");
        return;
      }
    }
    keyboard.current.setInput(value);
  };

  const onKeyPress = (button) => {
    if (button === BUTTONS.ENTER) {
      setIsKeyboardVisible(false);
    }
    if (button === BUTTONS.SHIFT || button === BUTTONS.LOCK) handleShift();
  };

  const handleShift = () => {
    let currentLayout = keyboard.current.options.layoutName;
    let shiftToggle = currentLayout === LAYOUTS.DEFAULT ? LAYOUTS.SHIFT : LAYOUTS.DEFAULT;
    setLayout(shiftToggle);
  };

  const handleChange = (event) => {
    if (isPhone) {
      onChange(event);
    } else {
      onChange(event);
    }
  };

  useEffect(() => {
    function clickHanlder(e) {
      if (!(e.target.nodeName === INPUT) && !e.target.classList.contains("hg-button")) {
        setIsKeyboardVisible(false);
      }
    }

    window.addEventListener("click", clickHanlder);
    return window.removeEventListener("click", clickHanlder, true);
  }, []);

  return (
    <div className="main-input">
      <label className="capitalize" htmlFor={name}>
        {`${label || name}${required ? "*" : ""}`}
      </label>
      {isPhone ? (
        <PhoneInput
          placeholder=""
          country={"us"}
          value={value}
          onChange={(phone) => onChange(phone)}
          onFocus={handleFocus}
          inputProps={{ className: "main-input__phone", name }}
        />
      ) : (
        <input
          maxLength={maxLength}
          id={name}
          name={name}
          type={type}
          className={classNames("outline-0", {
            capitalize: type === "text",
          })}
          autoComplete="off"
          value={value}
          onChange={onChange}
          onFocus={handleFocus}
          ref={inputRef}
          {...(list && { list })}
        />
      )}

      {invalid && <span className="main-input__error">{errorMsg}</span>}
      <div
        className={classNames("main-input__kb", {
          hidden: !isKeybordVisible,
          "main-input__kb_bottom": position === "bottom",
          "main-input__kb_top": position === "top",
        })}
      >
        <Keyboard
          autoUseTouchEvents
          maxLength={maxLength}
          keyboardRef={(r) => (keyboard.current = r)}
          layoutName={layout}
          onChange={handleChange}
          onKeyPress={(e) => onKeyPress(e)}
        />
      </div>
    </div>
  );
};

MainInput.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object.isRequired,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  errorMsg: PropTypes.string,
  list: PropTypes.string,
  position: PropTypes.string,
  defaultValue: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  isPhone: PropTypes.bool,
};

export default MainInput;
