import QRCode from "react-qr-code";
import { RAYALDEE_QR_URL } from "../../constants";
import { sidebarContent } from "./content";

const LeftSidebar = () => {
  return (
    <div className="left-sidebar">
      <div className="left-sidebar__qr">
        <p>{sidebarContent.scan}</p>
        <div className="p-8 qr-wrap">
          <QRCode
            size={256}
            value={RAYALDEE_QR_URL}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            viewBox={`0 0 256 256`}
            fgColor="#001f5b"
          ></QRCode>
        </div>
        <p className="abbreviations" dangerouslySetInnerHTML={{ __html: sidebarContent.footer }}></p>
      </div>
    </div>
  );
};

export default LeftSidebar;
