export const headerContent = {
  indication: {
    type: "indication",
    title: "Indication",
    header: "Indication and Limitations of Use",
    content: `Rayaldee<span class="sup">&reg;</span>&nbsp;(calcifediol) extended-release 30&nbsp;mcg capsules is indicated for the 
    treatment of secondary hyperparathyroidism in adults with stage 3 or 4 chronic 
    kidney disease and serum total <span class='whitespace-nowrap'>25-hydroxyvitamin</span> D levels less than 30&nbsp;ng/mL. 
    Rayaldee is not indicated in patients with stage 5 chronic kidney disease or end-stage renal disease on dialysis. `,
    link: "https://rayaldee.com/wp-content/themes/rayaldee/pdfs/rayaldee-pi.pdf",
  },
  important: {
    type: "important",
    title: "Important Safety Information",
    header: "Important Safety Information",
    content: `<li><b><i>Hypercalcemia</i></b>: Excessive administration of vitamin D compounds, including 
      Rayaldee, can cause hypercalcemia and hypercalciuria. Severe hypercalcemia 
      due to substantial overdosage of vitamin D and its metabolites may require 
      emergency attention. Patients should be informed about the symptoms of 
      elevated calcium.</li>
      <li><b><i>Digitalis toxicity</i></b>: Potentiated by hypercalcemia of any cause. Monitor serum 
      calcium and signs and symptoms of digitalis toxicity more frequently when 
      initiating or adjusting the dose of Rayaldee.</li>
      <li><b><i>Adynamic Bone Disease</i></b>: Monitor for abnormally low levels of intact 
      parathyroid hormone (iPTH) levels when using Rayaldee, and adjust dose if 
      needed.</li>
      <li>The most common adverse reactions (≥3% and more frequent than placebo) 
      were anemia, nasopharyngitis, increased blood creatinine, dyspnea, cough, 
      congestive heart failure and constipation.</li> 
      <li>Care should be taken while dosing Rayaldee with cytochrome P450 inhibitors, 
      thiazides, cholestyramine or drugs stimulating microsomal hydroxylation due to 
      the potential for drug interactions.</li>
      <li>Serum calcium should be below 9.8&nbsp;mg/dL before initiating treatment.</li>
      <li>Monitor serum calcium, phosphorus, <span class='whitespace-nowrap'>25-hydroxyvitamin</span> D and iPTH 3 months 
      after starting therapy or changing dose.</li>`,
    link: "https://rayaldee.com/wp-content/themes/rayaldee/pdfs/rayaldee-pi.pdf",
  },
  prescribing: { title: "Prescribing Information", type: "prescribing" },
  contact: { title: "Contact us", type: "contact" },
  usOnly: "For US audiences only",
};
