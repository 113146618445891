import classNames from "clsx";
import PropTypes from "prop-types";
import StarIcon from "../../assets/icons/star.png";
import { questionContent } from "./content";

const QuestionAnswered = ({ selected, question }) => {
  return (
    <div className="question-answered">
      <div className="question-answered__header">
        <h3>{questionContent.anwered.selected}</h3>
        <div
          className={classNames("question__current", {
            "question-answered__wrong": !selected.points,
            "question-answered__right": selected.points,
          })}
        >
          <div>{selected.title}</div>
          <div>
            {selected.points ? (
              <span className="question-answered__text-right">&#10003;</span>
            ) : (
              <span className="question-answered__text-wrong">&times;</span>
            )}
          </div>
        </div>
      </div>
      <div className="question__correct">
        <h3 className="flex items-start gap-1">
          <img className="w-6 h-6" src={StarIcon} alt="star"></img>
          {questionContent.anwered.correct}
        </h3>
        {question.correct && <span dangerouslySetInnerHTML={{ __html: question.correct }}></span>}
      </div>
      <div className="question__fact ">{question.fact && `Fact: ${question.fact}`}</div>
      {question.footnote && (
        <div className="question__footnote" dangerouslySetInnerHTML={{ __html: question.footnote }}></div>
      )}
      <div className="question__source">
        {question.source && <span dangerouslySetInnerHTML={{ __html: question.source }}></span>}
      </div>
    </div>
  );
};

QuestionAnswered.propTypes = {
  question: PropTypes.object.isRequired,
  selected: PropTypes.object.isRequired,
};

export default QuestionAnswered;
