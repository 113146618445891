import { footerContent } from "./content";

const Footer = () => {
  return (
    <footer>
      <div>
        {footerContent.rayaldee}
        <span className="sup">&reg;</span>
        {footerContent.text}
      </div>
    </footer>
  );
};

export default Footer;
