import PropTypes from "prop-types";
import classNames from "clsx";

const Spinner = ({ inverse = false }) => {
  return <div className={classNames("spinner", { spinner__inverse: inverse })} />;
};

Spinner.propTypes = {
  inverse: PropTypes.bool,
};

export default Spinner;
