import 'react-simple-keyboard/build/css/index.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Layout from './components/Layout/Layout'
import { AppStateProvider } from './context/AppContext'
import { ModalProvider } from './context/ModalContext'
import Activity from './pages/Activity/Activity'

function App() {
  return (
    <AppStateProvider>
      <ModalProvider>
        <Layout>
          <Activity />
        </Layout>
        <ToastContainer />
      </ModalProvider>
    </AppStateProvider>
  )
}

export default App
