export const endContent = {
  thanks: "Thanks for Playing!",
  points: "Points",
  rank: "Rank",
  infoMsg: "Thank you for participating in the quiz challenge. We hope you found it fun and informative. ",
  findMore:
    "To ﬁnd out more about Rayaldee, visit our Conference Resource Hub to browse highlighted videos, resources, and publications.",
  scan: "Scan to access the Rayaldee Conference Resource Hub",
  signUp: {
    start: "Sign up to be",
    link: "contacted",
    end: "by a representative.",
  },
  exit: "Exit Quiz",
};
