export const modalContent = {
  contact: {
    title: "Sign up to have a representative get in touch.",
    send: "Submit",
    email: "medicalinformation@opko.com.",
    subheader: "To reach Medical Affairs, email ",
    required: "*Required field.",
    contactMe: "In addition to having a representative contact me, please",
    sign: "Sign me up to be notified about Rayaldee news.",
    sendMe: "Send me information about OPKO Connect.",
    footer: "Message frequency may vary. You may opt out at any time.",
  },
  info: {
    list: "Please see <b>Full Prescribing Information</b>.",
  },
  resume: {
    continue: "Do you want to continue?",
    yes: "YES!",
  },
};
