export const beginContent = {
  header: "Test Your Knowledge: CKD & SHPT",
  description: "Take the quiz! Show off your knowledge of SHPT in patients with CKD.",
  subheader: "Check the leaderboard to see how you stack up against your colleagues.",
  required: "*Required field.",
  how: "How to Play",
  hint: "Enter your information below, and then tap <b>Start</b> to test your knowlage.",
  fields: {
    name: { title: "name", errorMsg: "Please enter your name" },
    practice: { title: "practice name", errorMsg: "" },
  },
  start: "Start",
};
