import { useContext } from "react";
import { useForm } from "react-hook-form";
import { SET_USER_DATA } from "../../context/actionTypes";
import { AppDispatchContext, STAGES } from "../../context/AppContext";
import api from "../../services/api";
import Button from "../Button/Button";
import MainInput from "../Input/MainInput";
import { beginContent } from "./content";
import PropTypes from "prop-types";

/* @Practice list is hardcoded, change it here */
const practices = ["practice1", "practice2"];
const DATALIST_ID = "practice-list";

const formFields = {
  name: { ...beginContent.fields.name, value: "name" },
  practice: { ...beginContent.fields.practice, value: "practice" },
};

const BeginActivity = ({ onStageChange = () => {} }) => {
  const dispatch = useContext(AppDispatchContext);
  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    const user = {
      ...data,
      points: 0,
      name: data.name.toLowerCase(),
      createdAt: new Date(),
    };
    dispatch({ type: SET_USER_DATA, payload: user });
    api.getPlayerFromLeaderboard(dispatch, user);
    onStageChange(STAGES.ACTIVITY);
  };

  return (
    <div className="begin-activity">
      <h1>{beginContent.header}</h1>
      <p>{beginContent.description}</p>
      <p>{beginContent.subheader}</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <MainInput
          name={formFields.name.value}
          control={control}
          errorMsg={formFields.name.errorMsg}
          required
        />
        <MainInput
          label={formFields.practice.title}
          name={formFields.practice.value}
          control={control}
          errorMsg={formFields.practice.errorMsg}
          list={DATALIST_ID}
          position="top"
        />
        <datalist id={DATALIST_ID}>
          {practices.map((practice) => (
            <option value={practice} key={practice} />
          ))}
        </datalist>
        <p className="text-white req-start">{beginContent.required}</p>
        <Button animate classes="begin-activity__start">
          {beginContent.start}
        </Button>
      </form>
    </div>
  );
};

BeginActivity.propTypes = {
  onStageChange: PropTypes.func.isRequired,
};

export default BeginActivity;
