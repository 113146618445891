import { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import Button from "../Button/Button";
import { modalContent } from "./content";

const ResumeModal = () => {
  let { handleModal } = useContext(ModalContext);

  return (
    <div className="resume-modal">
      {modalContent.resume.continue}
      <Button onClick={() => handleModal()}>
        <div className="text-black">
          <b>{modalContent.resume.yes}</b>
        </div>
      </Button>
    </div>
  );
};

export default ResumeModal;
