import { toast } from "react-toastify";

const SUCCESSFULL_DEFAULT_MSG = "Successfully!";
const ERROR_DEFAULT_MSG = "Something went wrong!";

const TOAST_SETTINGS = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

export const successToast = (content = SUCCESSFULL_DEFAULT_MSG) => {
  toast.success(content, { ...TOAST_SETTINGS });
};

export const errorToast = (content = ERROR_DEFAULT_MSG) => {
  toast.error(content, { ...TOAST_SETTINGS });
};
