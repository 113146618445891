import classNames from "clsx";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { SET_PROGRESS, UPDATE_POINTS } from "../../context/actionTypes";
import { AppContext, AppDispatchContext } from "../../context/AppContext";
import { questionContent } from "./content";
import QuestionAnswered from "./QuestionAnswered";

const Question = ({ question, activeQuestion, onNextClick, onAnswer }) => {
  const [selected, setSelected] = useState(null);
  const [answered, setAnswered] = useState(false);
  const dispatch = useContext(AppDispatchContext);
  const { currentPoints } = useContext(AppContext);

  const handleNextQuestion = () => {
    if (answered) {
      setAnswered(false);
      onNextClick();
      setSelected(null);
    } else {
      setAnswered(true);
      dispatch({ type: SET_PROGRESS });
      if (selected && selected.points > 0) {
        onAnswer();
        dispatch({ type: UPDATE_POINTS, payload: currentPoints + selected.points });
      }
    }
  };

  if (!("answers" in question) && question.answers.length === 0) {
    return null;
  }

  return (
    <div
      className={classNames("question", {
        hidden: question.id !== activeQuestion.id,
      })}
    >
      <div className="w-full px-8">
        <h3>{questionContent.title(question.number)}</h3>
        <span className="question__title">{question.title}</span>
      </div>

      {answered ? (
        <QuestionAnswered selected={selected} question={question} />
      ) : (
        <>
          <div className="question__answers">
            {question.answers.map((answer) => (
              <div
                className={classNames("question__answers_answer ", {
                  question__selected: selected?.id === answer.id,
                })}
                key={answer.id}
                onClick={() => setSelected(answer)}
              >
                {answer.title}
              </div>
            ))}
          </div>
        </>
      )}

      <button className="question__submit" disabled={!selected} onClick={handleNextQuestion}>
        {answered ? "Next" : "Submit"}
      </button>
    </div>
  );
};

Question.propTypes = {
  question: PropTypes.shape({
    answers: PropTypes.array,
    id: PropTypes.string,
    number: PropTypes.number,
    correct: PropTypes.string,
    footnote: PropTypes.string,
    source: PropTypes.string,
    fact: PropTypes.string,
    title: PropTypes.string,
  }),
  activeQuestion: PropTypes.shape({
    id: PropTypes.string,
  }),
  onNextClick: PropTypes.func.isRequired,
  onAnswer: PropTypes.func.isRequired,
};

export default Question;
