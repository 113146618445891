import emailjs from "@emailjs/browser";
import { useContext, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import { PUBLIC_KEY, SERVICE_ID, TEMPLATE_ID } from "../../constants";
import { AppContext } from "../../context/AppContext";
import { ModalContext } from "../../context/ModalContext";
import api from "../../services/api";
import { successToast } from "../../services/toast";
import Button from "../Button/Button";
import MainInput from "../Input/MainInput";
import { modalContent } from "./content";

const formFields = {
  name: { title: "name", errorMsg: "Please enter name." },
  practice: { title: "practice name", value: "practice", errorMsg: "Please enter practice." },
  phone: { title: "phone", errorMsg: "Please enter phone." },
  email: { title: "email", errorMsg: "Please enter email." },
};

const ContactUs = () => {
  const { register, handleSubmit, reset, control } = useForm();
  let { handleModal } = useContext(ModalContext);
  const [loading, setLoading] = useState(false);
  const formRef = useRef();
  const { userData } = useContext(AppContext);

  const onSubmit = (data) => {
    setLoading(true);
    const sendData = {
      ...data,
      phone: data.phone ? data.phone : "",
    };
    api.addContactData(sendData);
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, formRef.current, PUBLIC_KEY).then(
      () => {
        successToast();
        setLoading(false);
        handleModal();
        reset();
      },
      (error) => {
        console.error(error.text);
        setLoading(false);
      }
    );
  };

  return (
    <div className="contact-modal ">
      <div className="w-4/5">
        <h1 className="contact-modal__title">
          <b>{modalContent.contact.title}</b>
        </h1>
        <h2>
          {modalContent.contact.subheader}
          <b>{modalContent.contact.email}</b>
        </h2>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="contact-modal__form" autoComplete="off" ref={formRef}>
        <MainInput
          required
          name={formFields.name.title}
          control={control}
          errorMsg={formFields.name.errorMsg}
          defaultValue={userData?.name ? userData.name : ""}
        />
        <MainInput
          name={formFields.practice.value}
          label={formFields.practice.title}
          control={control}
          defaultValue={userData?.practice ? userData.practice : ""}
        />
        <MainInput name={formFields.phone.title} control={control} isPhone errorMsg={formFields.phone.errorMsg} />
        <MainInput
          required
          name={formFields.email.title}
          control={control}
          type="email"
          position="top"
          errorMsg={formFields.email.errorMsg}
        />
        <div className="text-white">{modalContent.contact.required}</div>
        <div className="text-white text-xl">{modalContent.contact.contactMe}</div>
        <div className="contact-modal__checkboxes">
          <input name="sign" {...register("sign")} type="checkbox" />
          {modalContent.contact.sign}
        </div>
        <div className="contact-modal__checkboxes">
          <input name="send" {...register("send")} type="checkbox" />
          {modalContent.contact.sendMe}
        </div>
        <Button disabled={loading}>{modalContent.contact.send}</Button>
        <div className="contact-modal__footer">{modalContent.contact.footer}</div>
      </form>
    </div>
  );
};

export default ContactUs;
