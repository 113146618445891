import pdf from "../../assets/pdf/rayaldee-pi.pdf";

const PrescribingModal = () => {
  return (
    <div className="relative w-[1000px]">
      <iframe title="pdf" src={`${pdf}#view=FitH`} style={{ width: "100%", height: 800 }} frameBorder="0"></iframe>
    </div>
  );
};

export default PrescribingModal;
