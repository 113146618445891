import { createContext, useReducer } from "react";
import {
  GET_CAREGORIES,
  GET_LEADERBOARD,
  GET_LEADERBOARD_EMPTY,
  GET_LEADERBOARD_SUCCESS,
  LOADING_CATEGORIES,
  RESET_STATE,
  SET_PROGRESS,
  SET_TOTAL_PROGRESS,
  SET_USER_DATA,
  STAGE_ONE_PASSED,
  UPDATE_POINTS,
  UPDATE_STAGE,
} from "./actionTypes";

export const STAGES = {
  BEGIN: "begin",
  ACTIVITY: "activity",
  END: "end",
};

const initialAppState = {
  leaderboard: [],
  categories: [],
  stage: STAGES.BEGIN,
  isLoadingCategories: false,
  isLeaderboardLoading: false,
  userData: {},
  progress: 0,
  currentPoints: 0,
  totalProgress: 1,
  firstStagePassed: false,
};

export const AppContext = createContext(null);
export const AppDispatchContext = createContext(null);

// eslint-disable-next-line react/prop-types
export function AppStateProvider({ children }) {
  const [tasks, dispatch] = useReducer(appReducer, initialAppState);

  return (
    <AppContext.Provider value={tasks}>
      <AppDispatchContext.Provider value={dispatch}>{children}</AppDispatchContext.Provider>
    </AppContext.Provider>
  );
}

function appReducer(state = initialAppState, action) {
  switch (action.type) {
    case GET_CAREGORIES:
      return {
        ...state,
        categories: action.payload,
        isLoading: false,
      };
    case LOADING_CATEGORIES:
      return {
        ...state,
        isLoading: action.payload,
      };
    case UPDATE_STAGE:
      return {
        ...state,
        stage: action.payload,
      };
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case GET_LEADERBOARD:
      return {
        ...state,
        isLeaderboardLoading: true,
      };
    case GET_LEADERBOARD_SUCCESS:
      return {
        ...state,
        leaderboard: action.payload,
        isLeaderboardLoading: false,
      };
    case GET_LEADERBOARD_EMPTY:
      return {
        ...state,
        isLeaderboardLoading: false,
      };
    case UPDATE_POINTS:
      return {
        ...state,
        currentPoints: action.payload,
      };
    case SET_TOTAL_PROGRESS:
      return {
        ...state,
        totalProgress: action.payload,
      };
    case SET_PROGRESS:
      return {
        ...state,
        progress: state.progress + 1,
      };

    case STAGE_ONE_PASSED:
      return {
        ...state,
        firstStagePassed: true,
      };

    case RESET_STATE:
      return { ...initialAppState, leaderboard: state.leaderboard, categories: state.categories };
    default: {
      return state;
    }
  }
}
