import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { STAGE_ONE_PASSED } from "../../context/actionTypes";
import { AppDispatchContext, STAGES } from "../../context/AppContext";
import useIdleActions from "../../hooks/useIdleActions.hook";
import Spinner from "../Spinner/Spinner";
import Category from "./Category";
import { categoriesContent } from "./content";
import InfoHeader from "./InfoHeader";

const Categories = ({ categories, isLoading, onStageChange }) => {
  const dispatch = useContext(AppDispatchContext);
  const [activeCategory, setActiveCategory] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const { idleTime } = useIdleActions();

  useEffect(() => {
    if (categories && categories.length > 0) {
      setActiveCategory(categories[activeIndex]);
    }
  }, []);

  const handleChangeActiveCategory = () => {
    const nextIndex = activeIndex + 1;
    if (nextIndex < categories.length) {
      setActiveCategory(categories[nextIndex]);
      setActiveIndex(nextIndex);
      dispatch({ type: STAGE_ONE_PASSED });
    } else {
      onStageChange(STAGES.END);
    }
  };

  if (isLoading) {
    return <Spinner inverse />;
  }

  if (!categories || categories.length === 0) {
    return (
      <div className="categories__empty">
        <h1>{categoriesContent.empty}</h1>
      </div>
    );
  }

  return (
    <div className="categories">
      <InfoHeader categories={categories} current={activeCategory} />
      {categories.map((category) => (
        <Category
          key={category.id}
          activeCategory={activeCategory}
          category={category}
          onChangeCategory={handleChangeActiveCategory}
        />
      ))}
    </div>
  );
};

Categories.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.string,
      order: PropTypes.number,
      questions: PropTypes.array,
      source: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool,
  onStageChange: PropTypes.func.isRequired,
};

export default Categories;
