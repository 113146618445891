import { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import ContactUs from "../Modals/ContactUs";
import Info from "../Modals/Info";
import PrescribingModal from "../Modals/Prescribing";
import { headerContent } from "./content";

const Header = () => {
  let { handleModal } = useContext(ModalContext);

  const handleOpenModal = (type = headerContent.important.title) => {
    switch (type) {
      case headerContent.prescribing.type:
        handleModal(PrescribingModal);
        break;
      default:
        handleModal(<Info data={headerContent[type]}></Info>);
        break;
    }
  };

  return (
    <header className="header">
      <span className="mr-auto">{headerContent.usOnly}</span>
      <p onClick={() => handleOpenModal(headerContent.indication.type)}>{headerContent.indication.title}</p>
      <p onClick={() => handleOpenModal(headerContent.important.type)}>{headerContent.important.title}</p>
      <p onClick={() => handleOpenModal(headerContent.prescribing.type)}>{headerContent.prescribing.title}</p>
      <button className="header__contact" onClick={() => handleModal(<ContactUs />)}>
        <p>{headerContent.contact.title}</p>
      </button>
    </header>
  );
};

export default Header;
