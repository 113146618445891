import { collection, doc, getDoc, getDocs, orderBy, query, setDoc } from "firebase/firestore";
import {
  ANSWERS_COLLECTION,
  CATEGORIES_COLLECTION,
  CONTACT_COLLECTION,
  LEADERBOARD_COLLECTION,
  ORDER,
  POINTS,
  QUESTIONS_COLLECTION,
} from "../constants";
import {
  GET_CAREGORIES,
  GET_LEADERBOARD,
  GET_LEADERBOARD_EMPTY,
  GET_LEADERBOARD_SUCCESS,
  LOADING_CATEGORIES,
  SET_USER_DATA,
} from "../context/actionTypes";
import { db } from "../lib/init-firebase";
import { addUserRank } from "./addRank";

const ORDER_FIELDS = {
  CREATED_AT: "createdAt",
  DESC: "desc",
};

const _getAnswers = (questionId, categoryId) => {
  const result = [];
  const answersRef = collection(
    db,
    `${CATEGORIES_COLLECTION}/${categoryId}/${QUESTIONS_COLLECTION}/${questionId}/${ANSWERS_COLLECTION}`
  );
  const answersQuery = query(answersRef, orderBy(ORDER));
  getDocs(answersQuery)
    .then((answers) => {
      answers.docs.forEach((anwer) => {
        result.push({ ...anwer.data(), id: anwer.id });
      });
    })
    .catch(() => console.error(`Error while loading anwers, for ${ANSWERS_COLLECTION} collection.`));
  return result;
};

const _getQuestions = (categoryId) => {
  const result = [];
  const questionsRef = collection(db, `${CATEGORIES_COLLECTION}/${categoryId}/${QUESTIONS_COLLECTION}`);
  const questionsQuery = query(questionsRef, orderBy(ORDER));
  getDocs(questionsQuery)
    .then((questions) => {
      questions.docs.forEach((question) => {
        result.push({
          ...question.data(),
          id: question.id,
          answers: _getAnswers(question.id, categoryId),
        });
      });
    })
    .catch(() => console.error(`Error while loading questions, for ${QUESTIONS_COLLECTION} collection.`));
  return result;
};

const getAppData = (dispatch) => {
  dispatch({ type: LOADING_CATEGORIES, payload: true });
  const categoriesRef = collection(db, CATEGORIES_COLLECTION);
  const categoriesQuery = query(categoriesRef, orderBy(ORDER));
  getDocs(categoriesQuery)
    .then((categories) => {
      const appData = [];
      categories.docs.forEach(async (category) => {
        appData.push({
          ...category.data(),
          id: category.id,
          questions: _getQuestions(category.id),
        });
      });
      dispatch({ type: GET_CAREGORIES, payload: appData });
    })
    .catch(() => console.error(`Error while loading categories, for ${CATEGORIES_COLLECTION} collection.`));
};

const getLeaderboard = (dispatch) => {
  dispatch({ type: GET_LEADERBOARD });
  const collectionRef = collection(db, LEADERBOARD_COLLECTION);
  const leaderboardQuery = query(
    collectionRef,
    orderBy(POINTS, ORDER_FIELDS.DESC),
    orderBy(ORDER_FIELDS.CREATED_AT, ORDER_FIELDS.DESC)
  );
  getDocs(leaderboardQuery)
    .then((res) => {
      if (res.docs.length > 0) {
        const items = [];
        res.docs.forEach((user) => {
          items.push({ ...user.data() });
        });
        const ranked = addUserRank(items);
        dispatch({ type: GET_LEADERBOARD_SUCCESS, payload: ranked });
      } else {
        dispatch({ type: GET_LEADERBOARD_EMPTY });
      }
    })
    .catch(() => console.error(`Error while loading leaderboard, for ${LEADERBOARD_COLLECTION} collection.`));
};

const addPlayerToLeaderboard = async (data, dispatch) => {
  dispatch({ type: SET_USER_DATA, payload: data });
  await setDoc(doc(db, LEADERBOARD_COLLECTION, data.name), data, {
    merge: true,
  });
  getLeaderboard(dispatch);
};

const addContactData = async (data) => {
  try {
    await setDoc(doc(db, CONTACT_COLLECTION, data.email), data, {
      merge: true,
    });
  } catch (error) {
    console.error(`Error while proccess contact form, for ${CONTACT_COLLECTION} collection.`);
  }
};

const getPlayerFromLeaderboard = async (data, dispatch) => {
  const docRef = doc(db, LEADERBOARD_COLLECTION, data.name);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    dispatch({
      type: SET_USER_DATA,
      payload: {
        ...docSnap.data(),
        practice: data.practice,
        rank: data.rank ? data.rank : "NA",
      },
    });
  }
};

const api = {
  getData: (dispatch) => getAppData(dispatch),
  getLeaderboard: (dispatch) => getLeaderboard(dispatch),
  addPlayerToLeaderboard: (dispatch, data) => addPlayerToLeaderboard(data, dispatch),
  getPlayerFromLeaderboard: (dispatch, data) => getPlayerFromLeaderboard(data, dispatch),
  addContactData: (data) => addContactData(data),
};

export default api;
