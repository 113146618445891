import { useContext } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import ResumeModal from '../components/Modals/Resume'
import { RESET_STATE } from '../context/actionTypes'
import { AppDispatchContext } from '../context/AppContext'
import { ModalContext } from '../context/ModalContext'

const PAUSE_MINUTES = 3
const RESET_MINUTES = 5

export default function useIdleActions() {
  let { handleModal } = useContext(ModalContext)
  const dispatch = useContext(AppDispatchContext)
  const pauseTimer = useIdleTimer({ onIdle, onActive, timeout: 1000 * 60 * PAUSE_MINUTES })
  const resetTimer = useIdleTimer({ onIdle: onIdleReset, timeout: 1000 * 60 * RESET_MINUTES })

  function onIdle() {
    handleModal(<ResumeModal />)
  }

  function onActive() {
    // Here we can set actions, when user back to any actions
  }

  function onIdleReset() {
    dispatch({ type: RESET_STATE })
    handleModal()
  }

  return { pauseTimer, resetTimer }
}
