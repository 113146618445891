import classNames from "clsx";
import { createRef, useContext, useEffect } from "react";
import { AppContext, STAGES } from "../../context/AppContext";
import Spinner from "../Spinner/Spinner";
import { leaderboardContent } from "./content";
import UserRank from "./UserRank";

const Leaderboard = () => {
  const { leaderboard, isLeaderboardLoading, userData, stage } = useContext(AppContext);
  const userRef = createRef(null);
  const scrollRef = createRef(null);

  useEffect(() => {
    if (userRef.current) {
      userRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [userRef]);

  useEffect(() => {
    if (scrollRef.current && stage === STAGES.BEGIN) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [stage]);

  return (
    <div className="leaderboard">
      <div className="leaderboard__items">
        {isLeaderboardLoading && !leaderboard.length && <Spinner />}
        {leaderboard.length ? (
          <>
            <div ref={scrollRef}></div>
            <h1>{leaderboardContent.header}</h1>
            {leaderboard.map((user) => {
              const isUser = STAGES.END && user.name === userData.name;
              return (
                <div
                  ref={isUser ? userRef : null}
                  key={user.name}
                  className={classNames("leaderboard__places", {
                    leaderboard__active: isUser,
                    "bg-white": !isUser,
                  })}
                >
                  <div className="leaderboard__item">
                    <UserRank rank={user.rank} isActive={isUser}>
                      <div className="leaderboard__item-content">
                        <span>
                          {user.name} {user.practice && ` | ${user.practice}`}
                        </span>
                        <div>{`${user.points} ${leaderboardContent.points}`}</div>
                      </div>
                    </UserRank>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <>{!isLeaderboardLoading && <h1 className="text-center">{leaderboardContent.noLeaders}</h1>}</>
        )}
      </div>
    </div>
  );
};

export default Leaderboard;
