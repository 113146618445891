import PropTypes from "prop-types";
import { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import { modalContent } from "./content";
import PrescribingModal from "./Prescribing";

const Info = ({ data }) => {
  let { handleModalContent } = useContext(ModalContext);

  if (!data) return null;

  return (
    <div className="info-modal">
      <h1 className="info-modal__header">{data.header && data.header}</h1>
      {data.content && (
        <section className={data.type === "important" ? "px-6" : ""}>
          <ul className="list-disc" dangerouslySetInnerHTML={{ __html: data.content }}></ul>
        </section>
      )}
      <div
        onClick={() => {
          handleModalContent(PrescribingModal);
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: modalContent.info.list }}></span>
      </div>
    </div>
  );
};

Info.propTypes = {
  data: PropTypes.shape({
    header: PropTypes.string,
    content: PropTypes.string,
    type: PropTypes.string,
  }),
};

export default Info;
