import classNames from "clsx";
import PropTypes from "prop-types";

const Button = ({ onClick = () => {}, animate = false, disabled = false, children, classes = "button__default" }) => {
  return (
    <button
      onClick={onClick}
      type="submit"
      disabled={disabled}
      className={classNames("button__action", {
        button__animation: animate,
        button__hover: !animate,
        [classes]: classes,
      })}
    >
      <div className="button__content">{children}</div>
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  animate: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  classes: PropTypes.string,
};

export default Button;
