import classNames from "clsx";
import { useContext, useEffect, useMemo } from "react";
import OpkoLogo from "../../assets/logos/opko-renal.png";
import RayaldeeLogo from "../../assets/logos/rayaldee.png";
import BeginActivity from "../../components/BeginActivity/BeginActivity";
import Categories from "../../components/Categories/Categories";
import EndActivity from "../../components/EndActivity/EndActivity";
import Leaderboard from "../../components/Leaderboard/Leaderboard";
import LeftSidebar from "../../components/LeftSidebar/LeftSidebar";
import { UPDATE_STAGE } from "../../context/actionTypes";
import { AppContext, AppDispatchContext, STAGES } from "../../context/AppContext";
import api from "../../services/api";

const Activity = () => {
  const { categories, isLoading, stage, userData, leaderboard, currentPoints, firstStagePassed } =
    useContext(AppContext);
  const dispatch = useContext(AppDispatchContext);

  useEffect(() => {
    api.getData(dispatch);
    api.getLeaderboard(dispatch);
  }, []);

  useEffect(() => {
    if (firstStagePassed && currentPoints > userData.points) {
      api.addPlayerToLeaderboard(dispatch, { ...userData, points: currentPoints, createdAt: new Date() });
    }
  }, [userData, currentPoints, firstStagePassed]);

  const handleSwitchStage = (stage) => {
    dispatch({ type: UPDATE_STAGE, payload: stage });
  };

  const renderStage = useMemo(() => {
    switch (stage) {
      case STAGES.BEGIN:
        return <BeginActivity onStageChange={handleSwitchStage} />;
      case STAGES.ACTIVITY:
        return (
          <div className="activity__categories">
            <Categories categories={categories} isLoading={isLoading} onStageChange={handleSwitchStage} />
          </div>
        );
      case STAGES.END:
        return <EndActivity />;
      default:
        return null;
    }
  }, [stage, userData, leaderboard]);

  const renderSidebar = useMemo(() => {
    switch (stage) {
      case STAGES.ACTIVITY:
        return <LeftSidebar />;
      default:
        return <Leaderboard />;
    }
  }, [stage, userData]);

  return (
    <div className={classNames("activity", { "activity__bg-blue": !(stage === STAGES.BEGIN) })}>
      <div className="activity__sidebar">
        <img src={RayaldeeLogo} alt="rayaldee" className="activity__rayaldee-logo" />
        {renderSidebar}
      </div>
      <div className="activity__content">
        {stage === STAGES.BEGIN && <img src={OpkoLogo} alt="opko-logo" className="activity__opko-logo" />}
        {renderStage}
      </div>
    </div>
  );
};

export default Activity;
