import React, { createContext } from "react";
import Modal from "../components/Modal/Modal";
import useModal from "../hooks/useModal.hook";

let ModalContext;
let { Provider } = (ModalContext = createContext());

// eslint-disable-next-line react/prop-types
let ModalProvider = ({ children }) => {
  let { modal, handleModal, modalContent, handleModalContent, addCallback } = useModal();
  return (
    <Provider value={{ modal, handleModal, modalContent, handleModalContent, addCallback }}>
      <Modal />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };
