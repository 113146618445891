export const addUserRank = (items) => {
  const res = []
  items.reduce((prevValue, currentValue) => {
    if (!prevValue) {
      const data = { ...currentValue, rank: 1 }
      res.push(data)
      return data
    }
    if (prevValue.points === currentValue.points) {
      const data = { ...currentValue, rank: prevValue.rank }
      res.push(data)
      return data
    } else {
      const data = { ...currentValue, rank: prevValue.rank + 1 }
      res.push(data)
      return data
    }
  }, false)

  return res
}
