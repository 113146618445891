export const GET_CAREGORIES = 'GET_CATEGORIES'
export const LOADING_CATEGORIES = 'LOADING_CATEGORIES'
export const UPDATE_STAGE = 'UPDATE_STAGE'
export const SET_USER_DATA = 'SET_USER_DATA'
export const GET_LEADERBOARD = 'GET_LEADERBOARD'
export const GET_LEADERBOARD_SUCCESS = 'GET_LEADERBOARD_SUCCESS'
export const GET_LEADERBOARD_EMPTY = 'GET_LEADERBOARD_EMPTY'
export const SET_PROGRESS = 'SET_PROGRESS'
export const UPDATE_POINTS = 'UPDATE_POINTS'
export const SET_TOTAL_PROGRESS = 'SET_TOTAL_PROGRESS'
export const STAGE_ONE_PASSED = 'STAGE_ONE_PASSED'
export const RESET_STATE = 'RESET_STATE'
